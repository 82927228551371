// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-based-on-staticfuse-src-pages-404-js": () => import("./../node_modules/gatsby-plugin-based-on-staticfuse/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-based-on-staticfuse-src-pages-404-js" */),
  "component---node-modules-gatsby-plugin-based-on-staticfuse-src-templates-categories-archive-js": () => import("./../node_modules/gatsby-plugin-based-on-staticfuse/src/templates/categories/archive.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-based-on-staticfuse-src-templates-categories-archive-js" */),
  "component---node-modules-gatsby-plugin-based-on-staticfuse-src-templates-users-archive-js": () => import("./../node_modules/gatsby-plugin-based-on-staticfuse/src/templates/users/archive.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-based-on-staticfuse-src-templates-users-archive-js" */),
  "component---src-gatsby-plugin-based-on-staticfuse-templates-home-index-js": () => import("./../src/gatsby-plugin-based-on-staticfuse/templates/home/index.js" /* webpackChunkName: "component---src-gatsby-plugin-based-on-staticfuse-templates-home-index-js" */),
  "component---src-gatsby-plugin-based-on-staticfuse-templates-posts-archive-js": () => import("./../src/gatsby-plugin-based-on-staticfuse/templates/posts/archive.js" /* webpackChunkName: "component---src-gatsby-plugin-based-on-staticfuse-templates-posts-archive-js" */),
  "component---src-gatsby-plugin-based-on-staticfuse-templates-posts-single-js": () => import("./../src/gatsby-plugin-based-on-staticfuse/templates/posts/single.js" /* webpackChunkName: "component---src-gatsby-plugin-based-on-staticfuse-templates-posts-single-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-en-about-index-js": () => import("./../src/pages/en/about/index.js" /* webpackChunkName: "component---src-pages-en-about-index-js" */),
  "component---src-pages-en-index-js": () => import("./../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-share-js": () => import("./../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */)
}

