import theme from "gatsby-plugin-based-on-staticfuse/src/theme/theme";

const UVA_COLORS = {
  raisinBlack: "#1F1D21",
  redNcs: "#BC0031",
  gainsboro: "#DCDDDE",
  cultured: "#EBEBEC",
  white: "#FFFFFF",
  yaleBlue: "#004E92",
};

const publisherTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    // text: "#2D3748",
    primary: UVA_COLORS.redNcs,
    secondary: UVA_COLORS.yaleBlue,
    // muted: "#999",
    headings: UVA_COLORS.yaleBlue,
    links: theme.colors.blue,
    navLink: UVA_COLORS.raisinBlack,
    headerBg: UVA_COLORS.white,
    buttonBg: UVA_COLORS.redNcs,
    buttonBgHover: "#c62650",
  },
};

export default publisherTheme;
